import React from 'react';
import pathOr from 'ramda/src/pathOr';
import { Text, View } from '@react-pdf/renderer';
import {
  CurrentUser,
  InspectionComponentName,
  InspectionDataFieldsByKey,
  InspectionOnlineStatus,
  ResourceType,
  SensorType,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  getSensorsOrActuatorsSummaryFields,
  transformTemperatureValue,
  transformDoorValue,
  getSensorTypes,
  getRelativeTime,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { handleLongString, InspectionComponent } from './InspectionComponent';
import {
  DataField,
  getErrorOrWarningIconForPdfField,
  greenBase,
  pinkBase,
  Row,
  statusColors,
} from '../Pdf/utils';
import { parseISO } from 'date-fns';
import { DeviceIcon, SensorTypeIcon } from '../Pdf/Icons';

type Props = {
  siteId: string;
  data: InspectionDataFieldsByKey[];
  statisticsData?: InspectionDataFieldsByKey;
  currentUser?: CurrentUser;
};

type SensorTableColumnsProps = {
  currentUser: CurrentUser;
  showColumns?: string[];
  noLastCheckInTime?: boolean;
  isWhitelistSensorNull?: (uuid: string) => boolean;
};

export const getSensorsTableColumns = (props: SensorTableColumnsProps) => {
  const {
    showColumns = [],
    noLastCheckInTime = false,
    isWhitelistSensorNull,
    currentUser,
  } = props;

  const columns = [
    {
      header: 'Type',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: false,
      cellContent: ({ type }) => {
        return (
          <DataField
            field={type}
            transform={(field: SensorType[]) =>
              field ? <>{field.map(SensorTypeIcon)}</> : <></>
            }
            Container={({ children }) => <Row gap={0}>{children}</Row>}
          />
        );
      },
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        const getTypes = (s: InspectionDataFieldsByKey) => {
          const types = pathOr([], ['type', 'field'], s);
          return types.sort().join();
        };
        let sort = genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getTypes
        );
        const uuid = (b.UUID as InspectionDataFieldsByKey)?.field as string;
        return isWhitelistSensorNull && isWhitelistSensorNull(uuid) ? 1 : sort;
      },
    },
    {
      width: '17.5%',
      header: 'Sensor Name',
      cellContent: ({ id, title }) => (
        <DataField
          field={title}
          transform={field => field || ResourceType.SENSOR}
          style={{ fontWeight: 700 }}
        />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
          'field',
        ]);
      },
    },
    {
      width: '8%',
      header: noLastCheckInTime ? 'Status' : 'Status / Last Check-In',
      cellContent: ({ status, last_check_in }) => {
        const statusText = InspectionOnlineStatus[status?.field];
        return (
          <>
            <DataField
              field={status}
              transform={_ => {
                return statusText === 'Active'
                  ? 'Online'
                  : statusText === 'Inactive'
                  ? 'Offline'
                  : statusText;
              }}
              style={{
                fontWeight: statusText && 700,
                color: statusColors[status?.field.toLowerCase()],
              }}
            />
            <Text>
              {!noLastCheckInTime && (
                <div>{getRelativeTime(parseISO(last_check_in?.field))}</div>
              )}
            </Text>
          </>
        );
      },
    },
    {
      header: 'Last Reading',
      width: '8%',
      cellContent: ({ reading, last_reading, type }) => {
        const lastReading = reading || last_reading;
        const sensorTypes = getSensorTypes({ type });

        let displayedValue;
        if (reading && !isNaN(reading?.field) && reading?.field !== null) {
          displayedValue = transformTemperatureValue(
            reading?.field,
            currentUser
          );
        } else if (last_reading && last_reading?.field !== null) {
          const { temperature, humidity, binary } = JSON.parse(
            last_reading.field
          );
          if (sensorTypes.includes('AMBIENT')) {
            const temperatureDisplayValue = !isNaN(temperature)
              ? transformTemperatureValue(temperature, currentUser)
              : null;
            const humidityDisplayValue = !isNaN(humidity)
              ? `${humidity}%`
              : null;
            if (temperatureDisplayValue && humidityDisplayValue) {
              displayedValue = `${temperatureDisplayValue} @ ${humidityDisplayValue}`;
            } else if (humidityDisplayValue) {
              displayedValue = humidityDisplayValue;
            } else if (temperatureDisplayValue) {
              displayedValue = temperatureDisplayValue;
            } else {
              displayedValue = global.NOT_AVAILABLE;
            }
          } else if (sensorTypes.includes(SensorType.TEMPERATURE)) {
            displayedValue = transformTemperatureValue(
              temperature,
              currentUser
            );
          } else if (sensorTypes.includes(SensorType.HUMIDITY)) {
            displayedValue = `${humidity}%`;
          } else if (sensorTypes.includes(SensorType.BINARY)) {
            displayedValue = transformDoorValue(binary);
          } else {
            displayedValue = global.NOT_AVAILABLE;
          }
        } else {
          displayedValue = global.NOT_AVAILABLE;
        }
        const issues = getErrorOrWarningIconForPdfField(lastReading);
        return (
          <DataField
            field={lastReading}
            transform={() => handleLongString(displayedValue)}
            Container={({ children }) => (
              <Row gap={0} wrap>
                {children}
              </Row>
            )}
            annotation={_ =>
              issues && <View style={{ marginLeft: 3 }}>{issues}</View>
            }
          />
        );
      },
    },
    {
      width: '10%',
      header: 'Interval',
      cellContent: ({ interval_seconds }) => (
        <DataField
          field={interval_seconds}
          transform={field =>
            field !== undefined
              ? `${Math.floor(interval_seconds.field / 60)} mins`
              : global.NOT_AVAILABLE
          }
        />
      ),
    },
    {
      width: '12%',
      header: 'UUID',
      cellContent: ({ UUID }) => {
        const issues = getErrorOrWarningIconForPdfField(UUID);
        return (
          <DataField
            field={UUID}
            Container={({ children }) => (
              <Row gap={0} wrap>
                {children}
              </Row>
            )}
            transform={handleLongString}
            annotation={_ =>
              issues && <View style={{ marginLeft: 3 }}>{issues}</View>
            }
          />
        );
      },
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'UUID',
          'field',
        ]);
      },
    },
    {
      header: 'Firmware Version',
      width: '10%',
      cellContent: ({ firmware_version }) => (
        <DataField field={firmware_version} />
      ),
    },
    {
      header: 'Signal',
      width: '5%',
      cellContent: ({ signal_strength }) => (
        <DataField
          field={signal_strength}
          transform={field => (field ? `${field}dB` : global.NOT_AVAILABLE)}
        />
      ),
    },
    {
      header: 'Power',
      width: '5%',
      cellContent: ({ battery_level, battery_voltage }) => {
        const content = [
          ...(battery_voltage && battery_voltage.field !== null
            ? [<Text>{battery_voltage?.field}</Text>, <Text>mV</Text>]
            : []),
          ...(!battery_voltage &&
          !!battery_level &&
          battery_level?.field !== null
            ? [
                <Text>{Math.round(battery_level?.field * 100)}</Text>,
                <Text>%</Text>,
              ]
            : []),
        ];

        const batteryLevelIssues = getErrorOrWarningIconForPdfField(
          battery_level
        );
        const batteryVoltageIssues = getErrorOrWarningIconForPdfField(
          battery_voltage
        );

        return (
          <DataField
            field={battery_level}
            transform={() => <>{content}</>}
            annotation={_ => (
              <>
                {batteryLevelIssues && (
                  <View style={{ marginLeft: 3 }}>{batteryLevelIssues}</View>
                )}
                {batteryVoltageIssues && (
                  <View style={{ marginLeft: 3 }}>{batteryVoltageIssues}</View>
                )}
              </>
            )}
            Container={({ children }) => (
              <Row gap={0} wrap>
                {children}
              </Row>
            )}
          />
        );
      },
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'battery_level',
          'field',
        ]);
      },
    },
  ];
  return columns.filter(column =>
    !showColumns.length ? true : showColumns.includes(column.header)
  );
};

export const Sensors: React.FC<Props> = ({
  data,
  statisticsData,
  currentUser,
}) => {
  if (!currentUser) return null;

  const columns = getSensorsTableColumns({
    currentUser,
  });
  const tableData = (data as InspectionDataFieldsByKey[]) || [];
  const summaryFields = getSensorsOrActuatorsSummaryFields(data, {
    statisticsData,
    isUnpaired: true,
  });
  return (
    <InspectionComponent
      title={InspectionComponentName.SENSORS_NEARBY}
      titleIcon={<DeviceIcon type={InspectionComponentName.SENSORS_NEARBY} />}
      summaryFields={summaryFields}
      detailTable={{
        dataIsLoading: false,
        columns,
        data: tableData,
        headerWrap: true,
      }}
    />
  );
};
