import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import {
  SummaryField,
  getErrorOrWarningIconForPdfField,
  Row,
  RowFiller,
  sectionBorder,
  statusColors,
} from '../Pdf/utils';
import {
  accent,
  ambient,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { Hr } from '../Pdf/utils';
import { TableProps } from '@energybox/react-ui-library/dist/components/Table';
import {
  InspectionDetailField,
  InspectionSummaryField,
} from '@energybox/react-ui-library/dist/types/Inspection';

type InspectionProps = {
  title?: React.ReactNode | string;
  titleIcon?: React.ReactNode;
  subtitle?: string;
  summaryFields: InspectionSummaryField;
  detailFields?: InspectionDetailField[];
  detailTableHeader?: React.ReactNode;
  detailTable?:
    | TableProps
    | {
        title?: string;
        props: TableProps;
      }[];
  noTableDataMessage?: string;
};

type InspectionHeaderProps = {
  title?: React.ReactNode | string;
  titleIcon?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  summaryFields: InspectionSummaryField;
};

export const handleLongString = (s: string) => (
  <>
    {Array.from(s).map(c => (
      <Text>{c}</Text>
    ))}
  </>
);

export const ComponentHeader: React.FC<InspectionHeaderProps> = ({
  title,
  titleIcon,
  subtitle,
  summaryFields,
}: InspectionHeaderProps) => {
  const statusCount = summaryFields.statusCount
    ? ` (${summaryFields.statusCount})`
    : '';
  const inspectionResult =
    (summaryFields.status || global.NOT_AVAILABLE) + statusCount;
  return (
    <View style={{ rowGap: 5, marginTop: 2 }} wrap={false}>
      <Row gap={5}>
        {titleIcon}
        <View>
          <Text style={{ fontWeight: 600 }}>{title}</Text>
          <Text style={{ fontWeight: 600, color: accent.main }}>
            {subtitle}
          </Text>
        </View>
      </Row>
      <View style={{ backgroundColor: ambient.basePlus75, padding: '5 25' }}>
        <Row>
          <SummaryField
            width="50%"
            nameWidth="46%"
            field={{
              name: 'Inspection Result',
              value: inspectionResult,
            }}
            valueStyle={{
              fontWeight: 700,
              color: statusColors[summaryFields.status?.toLowerCase() || ''],
            }}
          />
          <SummaryField
            width="23.5%"
            field={{
              name: 'Status',
              value: summaryFields.onlineStatus || '',
              error: summaryFields.onlineStatusError,
            }}
            valueStyle={{
              fontWeight: 700,
              color:
                statusColors[summaryFields.onlineStatus?.toLowerCase() || ''],
            }}
          />
          <Text style={{ width: '26.5%' }}>
            {summaryFields.onlineStatusTime}
          </Text>
        </Row>
      </View>
    </View>
  );
};

export const PseudoTable = (
  props: TableProps & { noTableDataMessage?: string }
) => {
  const { columns, data, noTableDataMessage } = props;

  // Sort the data
  columns?.forEach(col => {
    const { isDefaultSort, comparator, defaultSortDirection } = col;
    if (data && isDefaultSort && comparator && defaultSortDirection) {
      data.sort((a, b) => comparator(a, b, defaultSortDirection));
    }
  });

  return (
    <View
      style={{
        border: `1px solid ${ambient.basePlus90}`,
      }}
    >
      <View style={{ padding: '3 5' }} wrap={false}>
        {columns && data?.length > 0 ? (
          <Row gap={3}>
            {columns.map(({ width, header, align = 'left' }) => (
              <Text
                style={{
                  width,
                  flexGrow: 1,
                  columnGap: 5,
                  textAlign: align,
                }}
              >
                {header}
              </Text>
            ))}
          </Row>
        ) : (
          <Text>{noTableDataMessage || 'Nothing configured'}</Text>
        )}
      </View>
      {columns &&
        data?.length > 0 &&
        data.map((datum, idx) => (
          <View
            wrap={false}
            key={idx}
            style={{
              padding: '1 5',
              backgroundColor: !!(idx % 2) ? '' : ambient.basePlus90,
            }}
          >
            <Row gap={5}>
              {columns.map(({ width, cellContent }) => (
                <View style={{ width, flexGrow: 1 }}>
                  {cellContent(datum, idx)}
                </View>
              ))}
            </Row>
          </View>
        ))}
    </View>
  );
};

// Create Document Component
export const InspectionComponent = ({
  title,
  titleIcon,
  subtitle,
  summaryFields,
  detailFields,
  detailTableHeader,
  detailTable,
  noTableDataMessage,
}: InspectionProps) => {
  return (
    <View
      style={{
        padding: '0 0 7',
        borderBottom: sectionBorder,
        rowGap: 7,
      }}
    >
      <View wrap={false} style={{ rowGap: 7, paddingTop: 5 }}>
        <ComponentHeader
          title={title}
          subtitle={subtitle}
          titleIcon={titleIcon}
          summaryFields={summaryFields}
        />

        {!!detailFields && (
          <View style={{ padding: '0 25' }}>
            <Row wrap>
              {detailFields.map((field, idx) => {
                if (!field) {
                  return <RowFiller key={idx} width="50%" />;
                }
                if (field.isHr) {
                  return <Hr key={idx} width="50%" />;
                }
                return (
                  <View
                    key={idx}
                    style={{
                      width: '50%',
                      margin: '3 0',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <Text
                      style={{
                        width: '47%',
                        fontWeight: 600,
                      }}
                    >
                      {field.name}:{' '}
                    </Text>
                    <View
                      style={{
                        width: '53%',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {handleLongString(field.value as string)}
                      <View
                        style={{
                          marginLeft: 3,
                          gap: 3,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {getErrorOrWarningIconForPdfField(field)}
                        <Text
                          style={{
                            fontStyle: 'italic',
                            fontSize: 7,
                            color: ambient.main,
                            alignSelf: 'flex-end',
                          }}
                        >
                          {field.description}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
              {!!(detailFields.length % 2) && <RowFiller width="50%" />}
            </Row>
          </View>
        )}
      </View>

      {(!!detailTableHeader || !!detailTable) && <Hr width="100%" />}

      <View style={{ gap: 12 }}>
        {!!detailTableHeader && detailTableHeader}

        {!!detailTable &&
          (Array.isArray(detailTable) ? (
            detailTable.map(({ title, props }, idx) => (
              <View key={idx}>
                {!!title && (
                  <Text style={{ fontWeight: 600, lineHeight: 1.5 }}>
                    {title}
                  </Text>
                )}
                <PseudoTable
                  {...props}
                  noTableDataMessage={noTableDataMessage}
                />
                {/* {formatTable(props, noTableDataMessage)} */}
              </View>
            ))
          ) : (
            <PseudoTable
              {...detailTable}
              noTableDataMessage={noTableDataMessage}
            />
          ))}
      </View>
    </View>
  );
};
