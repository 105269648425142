import React from 'react';
import {
  CurrentUser,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  TEMP_UNITS,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { getSensorsTableColumns } from './Sensors';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
  getSensorsOrActuatorsSummaryFields,
  transformTemperatureValue,
  transformTemperatureUnit,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
  currentUser?: CurrentUser;
};

export const VenstarThermostat: React.FC<Props> = ({ data, currentUser }) => {
  if (!currentUser) return null;

  const fields = [
    {
      name: 'IP Address',
      key: 'ip',
    },
    {
      name: 'Tstat Onboard Temp',
      key: 'onboard_temperature',
      transformValue: value => transformTemperatureValue(value, currentUser),
    },
    {
      name: 'MAC Address (UUID)',
      key: 'uuid',
    },
    {
      name: 'Control Temperature',
      key: 'control_temperature',
      transformValue: value => transformTemperatureValue(value, currentUser),
    },
    {
      name: 'Thermostat Model',
      key: 'thermostat_model',
      transformValue: value =>
        value ? `Venstar ${value}` : global.NOT_AVAILABLE,
    },
    {
      name: 'Thermostat Set Point',
      key: 'thermostat_cooling_setpoint',
      transformValue: coolingValue => {
        const heatingValue = (data.thermostat_heating_setpoint as InspectionDataField)
          ?.field as string;
        const coolingSetPoint = transformTemperatureValue(
          coolingValue,
          currentUser
        );
        const heatingSetPoint = transformTemperatureValue(
          heatingValue,
          currentUser
        );
        const thermostatMode = (data.thermostat_mode as InspectionDataField)
          ?.field as string;
        if (thermostatMode === ThermostatWorkingMode.HEATING) {
          return heatingSetPoint;
        } else if (thermostatMode === ThermostatWorkingMode.COOLING) {
          return coolingSetPoint;
        } else if (thermostatMode === ThermostatWorkingMode.AUTO) {
          return `${coolingSetPoint} - ${heatingSetPoint}`;
        }
        return global.NOT_AVAILABLE;
      },
    },
    {
      name: 'Configured HVAC Type',
      key: 'HVAC_type',
    },
    {
      name: 'Thermostat Mode',
      key: 'thermostat_mode',
      transformValue: value =>
        ThermostatWorkingModeLabel[value] || value || global.NOT_AVAILABLE,
    },
    {
      name: 'Configured Reversing Valve (Heatpump only)',
      key: 'reversing_valve',
    },
    {
      name: 'Control Mode',
      key: 'control_mode',
    },
    {
      name: 'Number of Sensors',
      key: 'number_of_sensors_detected',
      transformValue: detectedSensorNum => {
        const configuredSensorNum = (data.number_of_sensors_configured as InspectionDataField)
          ?.field as number;
        return `${
          isDefined(detectedSensorNum)
            ? detectedSensorNum
            : global.NOT_AVAILABLE
        } detected / ${
          isDefined(configuredSensorNum)
            ? configuredSensorNum
            : global.NOT_AVAILABLE
        } configured`;
      },
    },
    {
      name: 'Mapped Equipment',
      key: 'equipment_title',
    },
  ];
  const subtitle = getTitle(data);
  const thermostatSummaryFields = getSummaryFields(data);
  const sensorList = ((data.sensor_list ||
    []) as InspectionDataFieldsByKey[]).map(sensor => ({
    ...sensor,
    type: {
      field: ['TEMPERATURE'],
      error: null,
      warning: null,
    },
    vendor: {
      field: 'venstar',
      error: null,
      warning: null,
    },
    status: {
      field: 'online',
      error: null,
      warning: null,
    },
  }));
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(sensorList);
  const sensorsColumns = getSensorsTableColumns({
    currentUser,
    showColumns: ['Type', 'Sensor Name', 'Status', 'Last Reading', 'Power'],
    noLastCheckInTime: true,
  });
  return (
    <InspectionComponent
      title={InspectionComponentName.THERMOSTAT}
      titleIcon={<DeviceIcon type={InspectionComponentName.THERMOSTAT} />}
      subtitle={subtitle}
      summaryFields={thermostatSummaryFields}
      detailFields={getDetailFields(fields, data, InspectionDetailLevel.ALL)}
      detailTableHeader={
        <ComponentHeader
          title={InspectionComponentName.EXTERNAL_SENSORS}
          summaryFields={sensorsSummaryFields}
        />
      }
      detailTable={{
        dataIsLoading: false,
        columns: sensorsColumns,
        data: sensorList,
      }}
      noTableDataMessage="No Sensors Configured"
    />
  );
};
