import React from 'react';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { InspectionComponent } from './InspectionComponent';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
};

export const EdgeController: React.FC<Props> = ({ data }) => {
  const fields = [
    {
      name: 'Serial Number',
      key: 'serial_number',
    },
    {
      name: 'RSSI',
      key: 'rssi',
      transformValue: value =>
        !isNaN(value) && isDefined(value)
          ? `${value} dB`
          : global.NOT_AVAILABLE,
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'OS Version',
      key: 'ios_version',
    },
    {
      name: 'Gateway IP',
      key: 'northbound_ip',
    },
    {
      name: 'Southbound IP',
      key: 'southbound_ip',
    },
    {
      name: 'Local MQTT Host',
      key: 'mqtt_host',
    },
    {
      name: 'Control App Version',
      key: 'app_version',
    },
    {
      name: 'Connectivity',
    },
    {
      name: 'APN Settings',
    },
  ];
  const device = (data.device as InspectionDataField)?.field;
  const title =
    InspectionComponentName.EDGE_CONTROLLER + (device ? ` (${device})` : '');
  const subtitle = getTitle(data);
  const summaryFields = getSummaryFields(data);

  const props = {
    title,
    titleIcon: <DeviceIcon type={InspectionComponentName.EDGE_CONTROLLER} />,
    subtitle,
    summaryFields,
    detailFields: getDetailFields(fields, data, InspectionDetailLevel.ALL),
  };

  return <InspectionComponent {...props} />;
};
